.video {
    height: 100%;
    width: 100%;
    scroll-snap-align: start;
    position: relative;
}

.video__player
{
    height: 100%;
    width: 100%;
    object-fit: fill;
}