html {
  scroll-snap-type: y mandatory;
}

.App{
  background-color: black;
  height: 100vh;
  display: grid;
  place-items: center;
}

.app__videos{
  height: 800px;
  max-height: 800px;
  width: 80%;
  max-width: 500px;
  border-radius: 20px;
  overflow: scroll;
  scroll-snap-type: y mandatory;
  position: relative;


  border: 1px solid black;
}

/* Esconde a Scroll Bar no chrome, safari e opera */

.app__videos::-webkit-scrollbar{
  display: none;
}

/* Esconde a Scroll Bar no IE, Edge e Firefox */

.app__videos{
  -ms-overflow-style: none;
  scrollbar-width: none;
}


@media only screen and (max-width: 440px) {
  .app__videos {
    width: 100%;
    height: 100vh;
  }
}