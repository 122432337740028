.videoFooter {
    position: relative;
    bottom: 20%;
    color: white;
    margin-left: 30px;
}

.videoFooter__text {}

.videoFooter__text h3 {
    padding-bottom: 20px;
}

.videoFooter__text p {
    padding-bottom: 20px;
}

.videoFooter__music {
    display: flex;
}

.videoFooter__icon {}

.videoFooterMusic__text {
    width: 80%;
    overflow-x: hidden;
}

.videoFooterMusic__text p {
    animation: moveTheText 4s infinite linear;
}

.videoFooter__record {
    animation: spinTheRecord 3s infinite linear;
    position: absolute;
    bottom: 5px;
    right: 20px;
    height: 50px;
    filter: invert();
}

@keyframes spinTheRecord {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes moveTheText {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(-100%);
    }
}



@media only screen and (max-width: 440px) {
    .videoFooter {
        bottom: 22%;
    }

    .videoFooterMusic__text {
        width: 75%;
    }
}