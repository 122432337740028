.videoSidebar{
    position: absolute;
    top: 50%;
    right: 10px;
    color: white;
}

.videoSidebar__options{
    padding: 20px;
    text-align: center;
}

@media only screen and (max-width: 440px) {
    .videoSidebar {
      top: 46%;
    }
  }